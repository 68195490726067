// Import the functions you need from the SDKs you need
import { initializeApp} from "firebase/app";
import { getDatabase } from 'firebase/database';
import { getStorage, ref, uploadBytes,uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { getFirestore} from 'firebase/firestore';
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// const firebaseConfig = {
//   apiKey: "AIzaSyCRTmi0WWWiHC3vd9g53w3cBQk8xGrTVi4",
//   authDomain: "madelia-marketplace.firebaseapp.com",
//   projectId: "madelia-marketplace",
//   storageBucket: "madelia-marketplace.appspot.com",
//   messagingSenderId: "614985056570",
//   appId: "1:614985056570:web:10386328b38daf8f9c1003",
// };
// const firebaseConfig = {
//   apiKey: "AIzaSyDSCEdHMBj23jd5y8WVXtpCnatqvXfrmeQ",
//   authDomain: "file-upload-2cb65.firebaseapp.com",
//   projectId: "file-upload-2cb65",
//   storageBucket: "file-upload-2cb65.appspot.com",
//   messagingSenderId: "371549016599",
//   appId: "1:371549016599:web:e89169af65dfd50efa4252"
// };

// const firebaseConfig = {
//   apiKey: "AIzaSyCjnyXvfHmOMbxGp_sogFOIrqhKwxcsuBY",
//   authDomain: "jamspace-ecc9e.firebaseapp.com",
//   projectId: "jamspace-ecc9e",
//   storageBucket: "jamspace-ecc9e.appspot.com",
//   messagingSenderId: "526917815335",
//   appId: "1:526917815335:web:446de576f45363598f5202",
//   // measurementId: "G-FV2MBXJ2V2"
// };
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA38w73BQFjeDom1_XSaaJ1vkylcpeFCvw",
  authDomain: "euphorify-data-storage.firebaseapp.com",
  projectId: "euphorify-data-storage",
  storageBucket: "euphorify-data-storage.appspot.com",
  messagingSenderId: "723005036576",
  appId: "1:723005036576:web:c9fc307792a93830008445"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app); // Initialize Authentication

const storage = getStorage(app);
const db = getFirestore(app);
const database = getDatabase(app);
export { storage, ref, uploadBytes, getDownloadURL, database, uploadBytesResumable ,auth};